// Our main CSS
import '../css/app.css'
// layzyload and/or play/pause inline videos when they are in/out of view
const autoplaying_videos = document.querySelectorAll('video[playsinline]')

if (autoplaying_videos && autoplaying_videos.length) {
  import('./modules/inline-videos').then((module) => {
    module.default(autoplaying_videos)
  })
}

/**
 * 🦄 Alpine.js
 */
import Alpine from 'alpinejs'

window.Alpine = Alpine

Alpine.start()
